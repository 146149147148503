import styled from "styled-components"

export const Divider = styled.div`
    width: 100%;
    height: 2px;
    background: #F2F2ED;
    margin: 32px 0;
    outline: solid 0px #a77fde;
    outline-width: 0px;
    opacity: 1;
`
